<template>
    <el-container>
        <el-container>
            <el-main>
                <h2 class="depTitle">专项奖励填报</h2>
                <div class="hosrow">
                    <div></div>
                    <div>
                        <el-button type="primary" @click="depaddKeshi">新增</el-button>
                    </div>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="departmentUserName" label="人员名称" align="center">
                    </el-table-column>
                    <el-table-column prop="specialRewardName" label="专项奖励指标" align="center">
                    </el-table-column>
                    <el-table-column prop="fillingTime" label="填报时间" align="center" width="120">
                        <template slot-scope="scope">
                            <div>{{scope.row.fillingTime.split(' ')[0]}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="auditStatus" label="审核状态" align="center" width="100">
                        <template slot-scope="scope">
                            <!-- 审核状态（0审核通过  1审核未通过  2待审核  3撤销审核） -->
                            <div v-if="scope.row.auditStatus=='0'">审核通过</div>
                            <div v-else-if="scope.row.auditStatus=='1'">审核未通过</div>
                            <div v-else-if="scope.row.auditStatus=='2'">待审核</div>
                            <div v-else-if="scope.row.auditStatus=='3'">撤销审核</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="auditTime" label="审核时间" align="center" width="120">
                        <template slot-scope="scope">
                            <div>{{scope.row.auditTime.split(' ')[0]}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="auditUserId" label="审核人" align="center">
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" align="center" width="200px">
                        <template slot-scope="scope">
                            <div class="remakSty">{{scope.row.remark}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="120">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>
                            <el-button size="mini" type="danger" @click="deleteKeshi(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <el-dialog :title="diaTit" :visible.sync="AdddialogVisible" width="700px" :before-close="AdddialogVisibleClose">
            <add-in-man-fill ref='addinmanfill' :diaBtn='diaBtn' :tit='tit' :wardId='wardId' @valueChange='valueChange' :EditDateModel='AddDateModel' @hosList='hosList' />
        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
import addInManFill from "../AddInMan/addInManFill.vue";
export default {
    data() {
        return {
            tit: "专项奖励填报",
            value: true,
            diaBtn: "新增",
            diaTit: "新增",
            AdddialogVisible: false,
            pageNum: 1,
            pageSize: 10,
            total: 1,
            tableData: [],
            departmentId: window.sessionStorage.getItem("departmentId"),
            wardId: "",
            AddDateModel: {},
        };
    },
    components: {
        addInManFill,
    },
    created() {
        this.hosList();
    },
    methods: {
        valueChange(val) {
            console.log(val);
            // this.AddDateModel.type = val;
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.hosList();
        },
        depaddKeshi() {
            this.AdddialogVisible = true;
        },
        AdddialogVisibleClose() {
            this.AdddialogVisible = false;
            this.$refs.addinmanfill.clearFill();
            this.diaTit = "新增";
            this.diaBtn = "新增";
        },
        async deleteKeshi(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                id: row.id,
            });
            let res = await this.$axios.hosdelete(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 200) {
                this.hosList();
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        editKeshi(row) {
            // console.log(row);
            this.wardId = row.id;
            this.diaBtn = "修改";
            this.diaTit = "修改专项奖励填报";
            this.AdddialogVisible = true;
            this.AddDateModel = row;

            //  业务结束证明材料
            if (row.fileId) {
                // console.log(row.fileId)
                let imgss = [];
                let imgLen = row.fileId.split(",");
                for (let i = 0; i < imgLen.length; i++) {
                    imgss.push(imgLen[i]);
                }
                // console.log(imgss.join(','))
                this.$nextTick(() => {
                    this.$refs.addinmanfill.getFile(imgss.join(","));
                });
            } else {
                this.$nextTick(() => {
                    this.$refs.addinmanfill.getFile("");
                });
            }
        },
        async hosList() {
            this.AdddialogVisible = false;
            let data = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                deleteFlag: "0", //数据状态（0正常 1已删除
            };
            let { data: res } = await this.$axios.hosList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.RightDiv {
    margin: 0 auto;
}

.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}
.depart-addnum {
    width: 100%;
}

.AddUser {
    float: right;
    margin-bottom: 10px;
}

.AddUserDiv {
    width: 95%;
    margin: 0 auto;
}

.depar-dia {
    width: 100%;
}
.collapse-btn {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    background: rgb(134 140 146);
}
/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}

/deep/ .el-dialog__body {
    text-align: inherit;
}

.el-main {
    .table-title {
        display: flex;
        margin-bottom: 10px;
        .index-add {
            margin-left: 20px;
        }
    }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

.hosrow {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.activeStatus {
    background: rgb(102, 204, 0);
}

.noStatus {
    background: #ccc;
}

.noStatus,
.activeStatus {
    width: 25px;
    height: 25px;
    color: white;
    line-height: 25px;
    text-align: center;
    margin: 0 auto;
}

/deep/ .el-input,
/deep/ .el-date-editor {
    width: 300px;
}
</style>
